* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.subs__couple .photo__wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#Main .cards__actions .found__user .all__photos {
    background: #dedede;
}

.found__user .gender__img {
    width: 12px;
    margin-right: 5px;
}

.subs__couple.active .concrete__user .without__actions img {
    width: 100%;
    height: auto !important;
}
a.link_100 {
    width: 100%;
    height: 100%;
}
.subs__couple .photo__wrapper .concrete__user .with__action .user__avatar {
    border: 1px solid #ffffff !important;
}

.subs__couple .concrete__user .without__actions .concrete__info {
    z-index: 9;
}

.profile__settings .prof__card .card__gender {
    height: 16px;
    /*display: block;*/
    margin: 0px 0 10px 0;
    text-align: center;
}

.subs__couple .concrete__user .without__actions {
    border: 1px solid #d4d7df;
    position: relative;
}
.like_overlay {
    background-image: url("../../images/project/accept-icon.png");
    left: 10px;

}
.dislike_overlay{
    background-image: url("../../images/project/reject-icon.png");
    right: 10px;

}
.dislike_overlay, .like_overlay{
position: absolute;
height: 80px;
width: 80px;
top: 10px;
z-index: 9999;
}
.subs__couple .concrete__user .without__actions::after {
    content: '';
    width: 100%;
    height: 70%;
    position: absolute;
    background: -webkit-gradient(linear, left top, left bottom, from(#00000000), to(#000000d7));
    background: linear-gradient(#00000000, #000000d7);
    top: 30%;
}

#Main {
    width: 100%;
    background: #F6F7F8;
    position: relative;
    /*margin-top: 30px;*/
}

#Auth {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#Main .popoup__rules {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: #ffffff;
    color: #13152d;
    width: 100%;
    height: 100%;
}

#Main .main__logo {
    max-width: 120px;
}

#Main .main__logo a {
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
}

#Main .main__logo a img {
    width: 100%;
    height: 100%;
}

#Main .menu__wrapper,.menu__wrapper__mobile {
    position: fixed;
    display: flex;
    height: unset;
    width: fit-content;
    max-width: 100vw;
    margin: auto;
    top: 20px;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 16px;
    z-index: 10;
}

#Main .my__card, .my__card__desktop {
    width: 60vw;
    margin-left: 0;
    padding: 20px;
    height: unset;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#Main .menu__center, .menu__center__desktop {
    display: flex;
    gap: 24px;
    margin: 0 auto;
    align-items: center;
}

#Main .my__card .card__photo {
    width: 104px;
    height: 104px;
    border-radius: 666px;
    border: none;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#Main .my__card .card__photo img {
    width: 100%;
}

#Main .my__card .card__name {
    font-size: 24px;
    font-weight: 400;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 11px;
}

#Main .my__card .card__gender {
    width: 100%;
    height: 20px;
    margin: 0 auto;
    margin-bottom: 7px;
    text-align: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#Main .my__card .gender__img {
    margin-right: 5px;
}

#Main .my__card .gender__img img {
    width: 18px;
    height: 18px;
}

#Main .my__card .in__search {
    text-align: center;
    color: #c19306;
    margin-bottom: 10px;
}

#Main .my__card .account__actions .my__stats {
    position: relative;
}

#Main .my__card .account__actions .my__stats:after {
    content: '';
    height: 1px;
    width: 275px;
    background: #c19306;
    position: absolute;
    bottom: -10px;
    left: -20px;
}

.menu__item {
    margin: 8px 0 !important;
    cursor: pointer;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu__item span a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu__item span a {
    font-size: 14px;
    padding: 8px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
}

#Main .my__card .menu__item:hover span a {
    background: #EAECF0;
}

#Main .my__card .menu__item span a img {
    width: max-content;
    height: 20px;
    margin: 0 auto 6px;
}

#Main .my__card .menu__item.active {
    font-weight: 700;
}

#Main .my__card .info {
    font-size: 16px;
}

#Main .my__card .info a {
    text-decoration: underline;
    color: #ffffff;
    font-size: 14px;
}

#Main .my__card .logout {
    background: #F8F8F8;
    height: 100%;
    border-radius: 16px;
    font-size: 16px;
    padding: 0px 20px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

#Main .my__card .logout:hover {
    font-weight: 400;
}

#Main .main__content {
    position: fixed;
    /*background: #f7f8fb;*/
    width: 100%;
    min-height: 100vh;
    /*min-height: 700px;*/
    z-index: 9;
    -webkit-transition: top 0.4s ease-in-out;
    -webkit-transition: left 0.4s ease-in-out;
    transition: top 0.4s ease-in-out;
    transition: left 0.4s ease-in-out;
    top: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    overflow: overlay;
    /*overflow-y: auto;*/
}
.match_item {
    width: 100%;
    height: 100%;
}
#Main .main__content.off {
    /*top: 32px;*/
    left: 275px;

}

#Main .content__burger {
    color: #13152d;
    font-size: 36px;
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: 12px;
    width: 30px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9;
}

#Main .content__burger::-moz-selection {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#Main .content__burger::selection {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#Main .content__logo {
    width: auto;
    background: #ffffff;
    margin: 10px;
    padding: 15px;
    border-radius: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-between;
    align-items: center;
}

#Main .content__logo img {
    width: 110px;
    margin: 0;
}

#Main .cards__actions {
    width: 100%;
    max-width: 320px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-height: 400px;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

#Main .cards__actions .found__user {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 9;
    border-radius: 10px;
}

#Main .cards__actions .found__user::after {
    content: '';
    width: 266px;
    /* background-color: white; */
    position: absolute;
    border-radius: 10px;
    bottom: -109px;
    z-index: 3;
}

#Main .cards__actions .found__user .all__photos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    width: 258px;
    height: 100%;
    min-height: 300px;
    border-radius: 10px;
    z-index: 9;
    max-height: 400px;
}

#Main .cards__actions .found__user .all__photos img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    z-index: 8;
}

#Main .cards__actions .found__user .default__avatar {
    position: absolute;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 666px;
    width: 90px;
    height: 90px;
    bottom: 0;
    transform: translateY(50%);
    z-index: 9;
    border: 1px solid #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.5);
}

#Main .cards__actions .found__user .default__avatar img {
    width: 100%;
}

#Main .cards__actions .found__user .found__data {
    width: 165px;
    height: 60px;
    position: absolute;
    bottom: -70px;
    color: #13152d;
    display: flex;
    gap: 8px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 9;
    text-align: center;
    background: #f7f8fb;
}

#Main .cards__actions .found__user .found__data .some__name {
    font-size: 17px;
    font-weight: 400;
}

#Main .cards__actions .found__user .found__data .some__detail {
    font-size: 11px;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#Main .cards__actions .found__user .found__data .some__detail .found__gender {
    margin-right: 8px;
}

#Main .cards__actions .found__user .found__data .some__detail .found__gender img {
    width: 14px;
    height: 14px;
}

#Main .cards__actions .found__actions {
    position: absolute;
    z-index: 9;
    bottom: 10px;
    height: 0px;
    width: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#Main .cards__actions .found__actions .found__remove, #Main .cards__actions .found__actions .found__like {
    width: 78px;
    height: 78px;
    border-radius: 666px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 35px;
    font-weight: 400;
    z-index: 9;
    background: #ffffff;
    cursor: pointer;
}

#Main .cards__actions .found__actions .found__remove img, #Main .cards__actions .found__actions .found__like img {
    width: 100%;
    height: 100%;
}

#Main .cards__actions .red__shadow, #Main .cards__actions .green__shadow {
    position: absolute;
    width: 140px;
    height: 78px;
    opacity: 0.35;
    z-index: 5;
}

#Main .cards__actions .red__shadow {
    background-image: none;
    left: -90px;
    display: none;
}

#Main .cards__actions .green__shadow {
    background-image: none;
    right: -90px;
    display: none;
}

.under {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}

.under:hover {
    font-weight: 400;
}

.under:first-child {
    margin-bottom: 10px;
}

.action__overlap .buble, .action__likes .buble, .action__message .buble {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #fabd00;
    background: #ffffff;
    width: 42px;
    height: 20px;
    border-radius: 666px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
}

.page__name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    display: none;
}

.page__name i {
    margin-right: 10px;
}

#Main .cards__actions .found__user::after {
    height: 515px;
    bottom: -115px;
}

/* width */
::-webkit-scrollbar {
    width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: none;
    background: white;
    /* border-radius: 6px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8D46EA;
    border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #7e3cd3;
}
.scroll_me {
    margin-top: 80px;
    /*-webkit-box-shadow: -8px 0 33px 3px rgba(0, 0, 0, 0.25);*/
    /*box-shadow: -8px 0 33px 3px rgba(0, 0, 0, 0.25);*/
    border-radius: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background: #f7f8fb;
    /*height: inherit;*/
    overflow-x: hidden;
    overflow-y: overlay;
    position: fixed;
    /*height: 100%;*/
    width: 100%;
    height: 100%;


}
.off .scroll_me {
    border-radius: 16px 0 0 0;
    margin-top: 32px;

}
.search .scroll_me {
    height: 100%;
}
.login .scroll_me {
    height: 100%;
    /*height: 100vh;*/
     border-radius: 0;
    margin-top: 0;
}
.settings  .scroll_me{
    height: 100%;
}
.account  .scroll_me{
    height: 100%;
}
.red_star {
    color: red;
}
.err_required.restore__pop {
    display: none;
}
.err_required.restore__pop.active {
    display: block;
}

.new_like {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 10px;
    z-index: 9999;
    background-image: url(../../images/project/New.svg);
    right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.desktop_menu_image {
    max-width: 24px;
}

@media screen and (min-width: 480px ) and (max-width: 979px) {
    .profile__settings .user__photos .all__photos {
        width: 200px;
        height: 300px;
}
}


@media screen and (max-width: 319px) {
    #settings {
        
        overflow: hidden;
    }

}
@media screen and (max-width: 479px) {
    /*#Main .main__logo {*/
    /*    display: none;*/
    /*}*/
    #Main .my__card .info {
        display: none;
    } 
    .subs__couple .concrete__user .without__actions {
        overflow: hidden;
        width: 138px;
        height: 213px;
    }
    .chart-wrap {
        margin-top: 15px;
    }
}
@media screen and (max-width: 980px) {
    #Main .menu__wrapper {
        bottom: 20px;
        top: unset;
    }

    .mobile__menu__wrapper {
        position: fixed;
        display: flex;
        height: unset;
        width: fit-content;
        max-width: 100vw;
        margin: auto;
        bottom: 20px;
        left: 0;
        right: 0;
        background: #fff;
        border-radius: 16px;
        z-index: 10;
    }

    #Main .my__card {
        width: 95vw;
        padding: 0 10px;
    }

    .my__card__desktop {
        width: 95vw;
        margin-left: 0;
        padding: 0 20px;
        height: unset;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu__center__desktop {
        display: none;
    }

    #Main .menu__center {
        width: 100%;
        justify-content: center;
    }

    #Main .my__card .menu__item span a {
        font-size: 13px;
    }

    #Main .my__card .logout {
        padding: 0px 14px;
        font-size: 14px;
        margin-left: 0px;
    }

    .subs__couple .photo__wrapper {
        padding: 0;
        margin-bottom: 165px;
    }

    .profile__settings {
        display: flex;
        flex-direction: column;
    }

    .profile__settings .user__photos {
        order: 3;
    }
     .about__profile {
         order: 2;
         margin-bottom: 30px;
     }

    #Main .cards__actions .found__user .found__data {
        bottom: -115px;
    }

    #Main .cards__actions .found__user .default__avatar {
        bottom: -10px;
    }
}

    @media screen and (min-width: 980px) {

    .account .scroll_me {
        height: inherit;
    }
    .settings  .scroll_me{
        height: inherit;
    }
    .chat .scroll_me{
        position: fixed;
        width: auto;
        /*min-width: 100%;*/
    }
    .chat .user__chat {
        display: -webkit-inline-box;
    }
    .login .scroll_me {
        position: fixed;
    }
    .scroll_me {
        position: relative;
        margin-top: 140px;
        height: calc(100% - 120px);
    }
    .search .scroll_me {
        height: inherit;
    }

    #Main .main__content.off {

        background: none;
    }

    #Main .page__name {
        color: #13152d;
        font-size: 24px;
        font-weight: 400;
        position: absolute;
        left: 12px;
        top: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    #Main .content__burger {
        display: none;
    }

    .content__logo {
        display: none !important;
    }

    #Main .cards__actions .found__actions {
        bottom: -52px;
        width: 400px;
    }
}

.card__photo {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ffffff !important;
    background: rgba(255, 255, 255, 0.5);
}

.to__settings {
    width: 257px;
    height: 41px;
    color: #ffffff;
    background: #fabd00;
    border-radius: 666px;
    border: none;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.to__settings:hover, .to__settings:active, .to__settings:focus {
    font-size: 15px;
}

.no__results {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    top: 50%;
    left: 50%;
    min-width: 360px;
    min-height: 400px;
    text-align: center;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.no__results.hidden {
    display: none;
}

.no__results .result__phraze {
    color: #13152d;
    margin-bottom: 30px;
}

.no__results .to__settings {
    width: 257px;
    height: 41px;
    color: #ffffff;
    background: #BD95F1;
    border-radius: 666px;
    border: none;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.no__results .to__settings:hover, .no__results .to__settings:active, .no__results .to__settings:focus {
    font-size: 15px;
}

.pulse__img {
    position: relative;
    margin-bottom: 60px;
}

.pulse__img .card__photo {
    z-index: 9;
    border: none;
    border-radius: 666px;
    overflow: hidden;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 109px;
    height: 109px;
}

.pulse__img .card__photo img {
    width: 100%;
}

.call__pulse {
    height: 0;
    width: 0;
    position: absolute;
    top: -6px;
    bottom: 0;
    left: -4px;
    right: 0;
    -webkit-animation: shake 2s infinite ease-in-out;
    animation: shake 2s infinite ease-in-out;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    z-index: -1;
}

.call__pulse:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    top: 0;
    background-color: #BD95F1;
    border-radius: 100%;
    -webkit-animation: pulse 2s infinite ease-in-out;
    animation: pulse 2s infinite ease-in-out;
}

.call__pulse:before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    top: 0;
    background-color: #BD95F1;
    border-radius: 100%;
    -webkit-animation: pulse 2s infinite ease-in-out;
    animation: pulse 2s infinite ease-in-out;
    margin: -14px -17px;
    height: 150px;
    width: 150px;
    opacity: .5;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}

.call__pulse:after {
    margin: -39px -42px;
    height: 200px;
    width: 200px;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
    opacity: .25;
}
.profile__settings .card__gender {

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.profile__settings span.found__gender {
    margin-right: 5px;
}
@-webkit-keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    25% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes shake {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    25% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    50% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

@media screen and (min-width: 980px) {
    .same__config {
        display: none;
    }

    #Main .cards__actions .found__user .found__data .some__name {
        width: 165px;
    }

    #Main .cards__actions .found__user .default__avatar {
        width: 105px;
        height: 105px;
        bottom: 45px;
    }

    #Main .cards__actions {
        /*width: 444px;*/
        top: 10%;
        height: 100%;
        max-height: 400px;
    }

    #Main .cards__actions .found__user::before {
        width: 290px;
    }

    #Main .cards__actions .found__user::after {
        width: 310px;
    }

    #Main .cards__actions .found__user .all__photos {
        width: 370px;
        height: 100%;
        min-height: 420px;
        max-height: 420px;
    }

    #Main .cards__actions .found__user .all__photos img {
        max-height: 420px;
    }

    #Main .cards__actions .found__user::after {
        padding: 2px 5px;
        height: 580px;
        bottom: -110px;
    }

    .subs__couple .photo__wrapper {
        padding-top: 22px;
        /*-webkit-box-pack: unset;*/
        /*-ms-flex-pack: unset;*/
        /*justify-content: unset;*/
    }
}

/*@media screen and (min-width: 1920px) {*/
    /*#Main .page__name {*/
        /*font-size: 36px;*/
    /*}*/

    /*#Main .cards__actions .found__user .default__avatar {*/
        /*width: 150px;*/
        /*height: 150px;*/
    /*}*/

    /*#Main .cards__actions {*/
        /*width: 570px;*/
        /*height: 100%;*/
        /*!*max-height: 675px;*!*/
    /*}*/

    /*#Main .cards__actions .found__user::before {*/
        /*width: 434px;*/
    /*}*/

    /*#Main .cards__actions .found__user::after {*/
        /*width: 444px;*/
        /*height: 800px;*/
        /*bottom: -125px;*/
    /*}*/

    /*#Main .cards__actions .found__user .all__photos {*/
        /*width: 434px;*/
        /*height: 675px;*/
        /*min-height: 675px;*/
        /*max-height: 675px;*/
    /*}*/

    /*#Main .cards__actions .found__user .all__photos img {*/
        /*width: 100%;*/
    /*}*/

    /*#Main .main__content {*/
        /*height: calc(100% - 60px);*/
        /*margin-top: 60px;*/
    /*}*/

    /*#Main .cards__actions .found__actions .found__remove, #Main .cards__actions .found__actions .found__like {*/
        /*width: 110px;*/
        /*height: 110px;*/
        /*font-size: 50px;*/
        /*z-index: 9;*/
    /*}*/

    /*#Main .cards__actions .found__actions {*/
        /*height: 110px;*/
        /*bottom: -212px;*/
    /*}*/

    /*#Main .cards__actions .red__shadow, #Main .cards__actions .green__shadow {*/
        /*height: 110px;*/
    /*}*/

    /*#Main .cards__actions .found__user .found__data .some__name {*/
        /*font-size: 24px;*/
        /*width: 405px;*/
    /*}*/

    /*#Main .cards__actions .found__user .found__data {*/
        /*width: 405px;*/
        /*bottom: -105px;*/
    /*}*/

    /*#Main .cards__actions .found__user .found__data .some__detail {*/
        /*font-size: 16px;*/
    /*}*/

    /*#Main .cards__actions .found__user .found__data .some__detail .found__gender img {*/
        /*width: 20px;*/
        /*height: 20px;*/
    /*}*/

    /*#Main .my__card .menu__item {*/
        /*font-size: 24px;*/
        /*margin-bottom: 20px;*/
    /*}*/

    /*#Main .my__card {*/
        /*width: 265px;*/
        /*margin-left: 15px;*/
    /*}*/

    /*.menu__item {*/
        /*width: 100% !important;*/
    /*}*/

    /*#Main .my__card .other__actions {*/
        /*width: 100%;*/
    /*}*/

    /*.under, .logout {*/
        /*font-size: 18px !important;*/
    /*}*/

    /*#Main .my__card .logout {*/
        /*font-size: 24px !important;*/
    /*}*/

    /*#Main .my__card .card__photo {*/
        /*width: 148px;*/
        /*height: 148px;*/
    /*}*/

    /*#Main .my__card .in__search {*/
        /*margin-bottom: 24px;*/
    /*}*/

    /*#Main .my__card .other__actions {*/
        /*margin-top: 40px;*/
    /*}*/

    /*#Main .my__card .account__actions .my__stats:after {*/
        /*bottom: -20px;*/
        /*left: -5px;*/
    /*}*/

    /*#Main .cards__actions .found__user::after {*/
        /*height: 804px !important;*/
    /*}*/
/*}*/

/*# sourceMappingURL=main.css.map */
#Main .cards__actions .found__user .default__avatar .button,
.likes .some__user .like_item{
    width: 100%;
    height: 100%;
}


.default__photo_background{
    width: 100%;
    height: 100%;
}

#Main .my__card .card__photo,
.profile__settings .user__photos .all__photos,
.profile__settings .user__photos .default__photo_background,
.profile__settings .prof__card .card__photo,
.pulse__img .card__photo,
.subs__couple .concrete__user .without__actions,
.subs__couple .photo__wrapper .concrete__user .with__action .user__avatar,
.some__chat__img,
.talker__pic,
.match_item ,
#Main .cards__actions .found__user .all__photos,
#Main .cards__actions .found__user .default__avatar .button,
.likes .some__user .like_item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f7f8fb;
}

.dislike_overlay, .like_overlay{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;
}

#Main.login .content__burger {
    display: none;
}
div#app {
    width: 100%;
    height: 100%;
}
#Main {
    height: 100%;
    background: #F6F7F8;
    transition: all 0s;
}
#Main.login {
    background: none;
}
#Main.login .scroll_me {
    background: none;
}

#Main .cards__actions .found__actions.hidden{display: none}

.login .scroll_me {
    margin-top: 0px!important;
}
.change_photo {
    text-align: center;
    margin-bottom: 25px;
    color: red;
}

.logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 8px 16px;
    border-radius: 12px;
}

.logout.active, .logout:hover {
    background-color: #EAECF0;
}

.logout svg {
    transition: transform 0.3s ease-in-out;
}

.logout__menu {
    position: absolute;
    top: 105%;
    right: 0;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 3px 12px rgba(0,0,0,.1);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: max-content;
}

.logout__name {
    font-weight: bolder;
}

.info-btn {
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
    padding: 4px 8px;
    border-radius: 8px;
    width: 100%;
    text-align: end;
}

.info-btn:hover {
    background-color: #EAECF0;
}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}
