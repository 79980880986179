

.cards__actions.hidden {
  display: none
}

.no__results.visible {
  display: flex !important;
}

.no__results {
  display: none !important;
}
#Main .cards__actions {
  width: 360px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  max-height: 400px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}

#Main .cards__actions .found__user {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9;
  border-radius: 10px;
}

#Main .cards__actions .found__user::after {
  content: '';
  width: 266px;
  /* background-color: white; */
  position: absolute;
  border-radius: 10px;
  bottom: -109px;
  z-index: 3;
}

#Main .cards__actions .found__user .all__photos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 258px;
  height: 100%;
  min-height: 398px;
  border-radius: 10px;
  z-index: 9;
  max-height: 400px;
}

#Main .cards__actions .found__user .all__photos img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  z-index: 8;
}

#Main .cards__actions .found__user .default__avatar {
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 666px;
  width: 90px;
  height: 90px;
  bottom: -35px;
  z-index: 9;
  border: 1px solid #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
}

#Main .cards__actions .found__user .default__avatar img {
  width: 100%;
}

#Main .cards__actions .found__user .found__data {
  width: 180px;
  height: 60px;
  position: absolute;
  bottom: -105px;
  color: #13152d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9;
  text-align: center;
}

#Main .cards__actions .found__user .found__data .some__name {
  font-size: 17px;
  font-weight: 400;
}

#Main .cards__actions .found__user .found__data .some__detail {
  font-size: 11px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#Main .cards__actions .found__user .found__data .some__detail .found__gender {
  margin-right: 8px;
}

#Main .cards__actions .found__user .found__data .some__detail .found__gender img {
  width: 14px;
  height: 14px;
}

#Main .cards__actions .found__actions {
  position: absolute;
  z-index: 9;
  bottom: -55px;
  height: 78px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#Main .cards__actions .found__actions .found__remove,#UserPack.cards__actions .found__actions .found__like {
  width: 78px;
  height: 78px;
  border-radius: 666px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 35px;
  font-weight: 400;
  z-index: 9;
  background: #ffffff;
}

#UserPack.cards__actions .red__shadow,#UserPack.cards__actions .green__shadow {
  position: absolute;
  width: 140px;
  height: 78px;
  opacity: 0.35;
  z-index: 5;
}

#UserPack.cards__actions .red__shadow {
  background-image: none;
  left: -90px;
}

#UserPack.cards__actions .green__shadow {
  background-image: none;
  right: -90px;
}
#UserPack.cards__actions .found__user::after {
  height: 515px;
  bottom: -115px;
}
@media screen and (min-width: 1280px) {
 #UserPack.cards__actions .found__user .found__data .some__name {
    width: 250px;
  }
 #UserPack.cards__actions .found__user .default__avatar {
    width: 105px;
    height: 105px;
  }
 #UserPack.cards__actions {
    width: 444px;
    height: 100%;
    max-height: 400px;
  }
 #UserPack.cards__actions .found__user::before {
    width: 290px;
  }
 #UserPack.cards__actions .found__user::after {
    width: 310px;
  }
 #UserPack.cards__actions .found__user .all__photos {
    width: 304px;
    height: 100%;
    min-height: 466px;
    max-height: 472px;
  }
 #UserPack.cards__actions .found__user .all__photos img {
    max-height: 472px;
  }
 #UserPack.cards__actions .found__user::after {
    height: 580px;
    bottom: -110px;
  }
  .subs__couple .photo__wrapper {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
  }
}

@media screen and (min-width: 1920px) {
  #Main .page__name {
    font-size: 36px;
  }
 #UserPack.cards__actions .found__user .default__avatar {
    width: 150px;
    height: 150px;
  }
 #UserPack.cards__actions {
    width: 570px;
    height: 100%;
    /*max-height: 675px;*/
  }
 #UserPack.cards__actions .found__user::before {
    width: 434px;
  }
 #UserPack.cards__actions .found__user .all__photos {
    width: 434px;
    height: 675px;
    min-height: 675px;
    max-height: 675px;
  }
 #UserPack.cards__actions .found__user .all__photos img {
    width: 100%;
  }
 #UserPack.cards__actions .found__actions .found__remove,#UserPack.cards__actions .found__actions .found__like {
    width: 110px;
    height: 110px;
    font-size: 50px;
    z-index: 9;
  }
 #UserPack.cards__actions .found__actions {
    height: 110px;
    bottom: -212px;
  }
 #UserPack.cards__actions .red__shadow,#UserPack.cards__actions .green__shadow {
    height: 110px;
  }
 #UserPack.cards__actions .found__user .found__data .some__name {
    font-size: 24px;
    width: 405px;
  }
 #UserPack.cards__actions .found__user .found__data {
    width: 405px;
    bottom: -105px;
  }
 #UserPack.cards__actions .found__user .found__data .some__detail {
    font-size: 16px;
  }
 #UserPack.cards__actions .found__user .found__data .some__detail .found__gender img {
    width: 20px;
    height: 20px;
  }
  #Main .my__card .menu__item {
    font-size: 24px;
    margin-bottom: 20px;
  }
  #Main .my__card {
    width: 265px;
    margin-left: 15px;
  }
  .menu__item {
    width: 100% !important;
  }
  #Main .my__card .other__actions {
    width: 100%;
  }
  .under, .logout {
    font-size: 18px !important;
  }
  #Main .my__card .logout {
    font-size: 24px !important;
  }
  #Main .my__card .card__photo {
    width: 148px;
    height: 148px;
  }
  #Main .my__card .in__search {
    margin-bottom: 24px;
  }
  #Main .my__card .other__actions {
    margin-top: 40px;
  }
  #Main .my__card .account__actions .my__stats:after {
    bottom: -20px;
    left: -5px;
  }
}

