
.main__content.off #scroll {
  /*opacity: 0;*/
}

.check__couple .subscribe__btn {
  width: 257px;
  height: 59px;
  color: #ffffff;
  background: -webkit-gradient(linear, left top, right top, from(#fabd00), to(#fbca33));
  background: linear-gradient(to right, #fabd00 0%, #fbca33 100%);
  border-radius: 666px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  margin: 15px auto 20px;

  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.check__couple .subscribe__btn:active, .check__couple .subscribe__btn.focus {
  font-size: 23px;
}

.check__couple .subscribe__btn.hidden {
  display: none;
}

.check__couple .photo__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 400px;
  margin: 0 auto;
  padding:0 10px;
}

.check__couple .photo__wrapper.hidden {
  display: none;
}

.check__couple .photo__wrapper .some__user {
  overflow: hidden;
  width: 152px;
  height: 245px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  background: #f7f8fb;
  border: 1px solid #ccc;
}

/*.check__couple .photo__wrapper .some__user:not(:first-child) {*/
/*margin-left: 30px;*/
/*}*/

/*.check__couple .photo__wrapper .some__user:nth-child(2n + 3) {*/
/*margin-left: 0;*/
/*}*/

.check__couple .photo__wrapper .some__user  {
  /*width: 100%;*/
  /*height: 100%;*/
  -webkit-filter: blur(15px);
  filter: blur(15px);
}

.subs__couple {
  display: none;
  padding-top: 22px;
}

.subs__couple.active {
  display: block;
}

.subs__couple .photo__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 95%;
  margin: 0 auto;
  padding:0 10px;
}

.subs__couple .concrete__user {
  position: relative;
  margin-bottom: 40px;
  background: #f7f8fb;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.subs__couple .concrete__user:not(:first-child) {
  /*margin-left: 30px;*/
}

.subs__couple .concrete__user:nth-child(2n + 3) {
  /*margin-left: 0;*/
}

.subs__couple .concrete__user .without__actions {
  overflow: hidden;
  width: 152px;
  height: 245px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  margin: 0 auto;
  /*margin-bottom: 15px;*/
  position: relative;
}

.subs__couple .concrete__user .without__actions img {
  width: 100%;
  height: 100%;
}

.subs__couple .concrete__user .without__actions .concrete__info {
  position: absolute;
  bottom: 35px;
  font-size: 12px;
  width: 90%;
}

.subs__couple .concrete__user .without__actions .concrete__info .concrete__name {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #fff;
}

.subs__couple .concrete__user .without__actions .concrete__info .concrete__dop {
  height: 22px;
}

.subs__couple .concrete__user .without__actions .concrete__info .concrete__dop img {
  width: 12px;
  height: 12px;
}

.subs__couple .photo__wrapper .concrete__user .with__action {
  width: 163px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  /*bottom: -51px;*/
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn, .subs__couple .photo__wrapper .concrete__user .with__action .chat__btn {
  width: 33px;
  height: 33px;
  background: #ffffff;
  border-radius: 666px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 22px;
  padding-left: 2px;
  padding-bottom: 2px;
}

.subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn {
  color: #d91a00;
  -webkit-box-shadow: 0 0 8px 2px rgba(217, 26, 0, 0.25);
  box-shadow: 0 0 8px 2px rgba(217, 26, 0, 0.25);
}

.subs__couple .photo__wrapper .concrete__user .with__action .chat__btn {
  color: #84be41;
  -webkit-box-shadow: 0 0 8px 2px rgb(0 0 0 / 25%);
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 25%);
}

.subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn img {
  width: 100%;
}

.subs__couple .photo__wrapper .concrete__user .with__action .chat__btn img {
  width: 100%;
  padding: 10px;
}

.subs__couple .photo__wrapper .concrete__user .with__action .user__avatar {
  width: 52px;
  height: 52px;
  margin: 0px 7px;
  overflow: hidden;
  border-radius: 666px;
  border-top: 1px solid #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f7f8fb;
}

.subs__couple .photo__wrapper .concrete__user .with__action .user__avatar img {
  width: 100%;
}

@media screen and (min-width: 980px) {
  /*.concrete__user:nth-child(2n + 3) {*/
  /*margin-left: 30px !important;*/
  /*}*/
  /*.concrete__user:nth-child(4n + 5) {*/
  /*margin-left: 0 !important;*/
  /*}*/
  .main__content.off #scroll {
    opacity: 1;
    /*padding-top: 32px;*/
  }
  .check__couple .photo__wrapper {
    max-width: 900px;
    /*margin-top: 20px;*/
  }
  .check__couple .photo__wrapper.hidden {
    display: none;
  }
  .check__couple .photo__wrapper .some__user {
    width: 197px;
    height: 307px;
  }
  /*.check__couple .photo__wrapper .some__user:nth-child(2n + 3) {*/
  /*margin-left: 30px !important;*/
  /*}*/
  /*.check__couple .photo__wrapper .some__user:nth-child(4n + 5) {*/
  /*margin-left: 0 !important;*/
  /*}*/
  .check__couple .photo__wrapper .some__user  {
    -webkit-filter: blur(15px);
    filter: blur(15px);
  }
  .subs__couple .photo__wrapper {
    max-width: 900px;
    margin-top: 20px;
  }
  .subs__couple .concrete__user .without__actions {
    width: 197px;
    height: 332px;
  }
  .subs__couple .photo__wrapper .concrete__user .with__action {
    width: 197px;
    bottom: -68px;

  }
  .subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn, .subs__couple .photo__wrapper .concrete__user .with__action .chat__btn {
    width: 42px;
    height: 42px;
    font-size: 26px;
  }
  .subs__couple .photo__wrapper .concrete__user .with__action .user__avatar {
    width: 72px;
    height: 72px;
    margin: 0px 15px;
  }
}
/*# sourceMappingURL=dynamic.css.map */

.noMoney {
  display: none;
  align-items: center;
  height: 70px;
  cursor: pointer;
  /*margin: 25px auto 20px;*/
  margin: 50px auto 30px;
}

.noMoney span{
  background: rgba(255, 0, 0, 0.7);
  padding: 15px;
  margin: 15px auto 20px;
  width: 261px;
  border-radius: 666px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
.noMoney.active {
  display: flex;

}
.noSubscribe {
  display: none;
  align-items: center;
  height: 70px;

}

.noSubscribe span{
  background: rgba(255, 0, 0, 0.7);
  padding: 15px;
  margin: 0 auto;
  width: 230px;
  border-radius: 10px;
  text-align: center;
}
.noSubscribe.active {
  display: flex;

}
.noMatches {
  display: none;
  align-items: center;
  height: 70px;

}

.noMatches span{
  color: #000;
  padding: 15px;
  margin: 0 auto;
  width: 230px;
  border-radius: 10px;
  text-align: center;
}
.noMatches.active {
  display: flex;
  height: 100%;
  justify-content: center;
}

