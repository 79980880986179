
    .menu__top__right {
        display: flex;
        align-items: center;
      flex-direction: row;
      width: fit-content;
      gap: 8px;
      cursor: pointer;
    }
    .main-container {
        min-height: calc(100vh - 70px);
    }
    .router-link-exact-active {
        background: #EAECF0;
    }
    #Auth{z-index: 10}
    .popup_click_text{cursor: pointer}
    @media (min-width:1280px) {
        .config__title {
            display: none
        }
    }
