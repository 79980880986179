.vue-slider {
  margin-top: 4px;
}

/* component style */
.vue-slider-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* rail style */
.vue-slider-rail {
  background-color: #d4d7df !important;
}

/* process style */
.vue-slider-process {
  height: 5px !important;
	background-color: #ffd400 !important;
}

/* mark style */
.vue-slider-mark {
  z-index: 4;
}
.vue-slider-mark:first-child .vue-slider-mark-step, .vue-slider-mark:last-child .vue-slider-mark-step {
  display: none;
}
.vue-slider-mark-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.16);
}
.vue-slider-mark-label {
  font-size: 14px;
  white-space: nowrap;
}

/* dot style */

.vue-slider-dot {
  width: 28px !important;
	height: 28px !important;
	border: 1px solid #d4d7df !important;
	background-color: #ffffff !important;
	border-radius: 666px;
}
	
.vue-slider-dot-handle {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}
.vue-slider-dot-handle-focus {
  box-shadow: 0px 0px 1px 2px rgba(250, 189, 0, 0.36);
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.vue-slider-dot-tooltip-inner {
  font-size: 14px;
  white-space: nowrap;
  padding: 2px 5px;
  min-width: 20px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  box-sizing: content-box;
  border: 1px solid #fabd00 !important;
 	background-color: #ffd400 !important;
}
.vue-slider-dot-tooltip-inner::after {
  content: "";
  position: absolute;
}
.vue-slider-dot-tooltip-inner-top::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-top-color: inherit;
}
.vue-slider-dot-tooltip-inner-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-bottom-color: inherit;
}
.vue-slider-dot-tooltip-inner-left::after {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-left-color: inherit;
}
.vue-slider-dot-tooltip-inner-right::after {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-right-color: inherit;
}

.vue-slider-dot-tooltip-wrapper {
  opacity: 0;
  transition: all 0.3s;
}
.vue-slider-dot-tooltip-wrapper-show {
  opacity: 1;
}

.pop__bingo {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(247, 248, 251, 0.9);
  z-index: -99;
  display: none;
}

.pop__bingo.active {
  display: block;
  z-index: 9999;
}

.bingo__content {
  width: 273px;
  height: 440px;
  background: url('../../images/bg/bingo.jpg') no-repeat center;
  background-size: cover;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 55px;
  transform: translate(-50%, 0%);
}
.bingo__btn.message__btn a {
  color: #000;
}
.bingo__logo {
  display: block;
  margin: 12px auto 36px;
  width: 109px;
  height: 36px;
}

.bingo__text {
  color: #fff;
  font-size: 38px;
  font-weight: 300;
  width: 100%;
  text-align: center;
  margin-bottom: 27px;
}

.bingo__subtext {
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.capit {
  text-transform: capitalize;
}

.bingo__photos {
  width: 100%;
  margin-bottom: 27px;
  display: inline-flex;
  justify-content: center;
}

.bingo__user {
  width: 95px;
  height: 95px;
  border: 1px solid #fff;
  background: #d4d7df;
  border-radius: 666px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bingo__user:first-child {
  margin-right: 38px;
}

.bingo__user img {
  width: 100%;
}

.bingo__buttons {
  width: 90%;
  margin: 0 auto;
}

.bingo__btn {
  width: 100%;
  height: 39px;
  border-radius: 666px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  font-size: 15px;
}

.message__btn {
  background: #fff;
  color: #fabd00;
  margin-bottom: 17px;
}

.cancel__btn {
  color: #fff;
  background: transparent;
  box-sizing: border-box;
  border: 2px solid #fff;
}

@media screen and (min-width: 1280px) {
  .bingo__content {
    width: 304px;
    height: 472px;
    top: 0;
    margin-top: 9%;
    transform: translateX(-100%);
  }

  .bingo__logo {
    width: 119px;
    height: 39px;
  }

  .bingo__text {
    font-size: 42px;
    margin-bottom: 30px;
  }

  .bingo__subtext {
    font-size: 13px;
  }

  .bingo__photos {
    margin-bottom: 30px;
  }

  .bingo__user {
    width: 104px;
    height: 104px;
  }

  .bingo__user:first-child {
    margin-right: 41px;
    font-size: 16px;
  }

  .bingo__btn {
    height: 42px;
  }

  .message__btn {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1920px) {
  .bingo__content {
    width: 444px;
    height: 705px;
    top: 0;
    margin-top: 5%;
    transform: translateX(-76%);
  }

  .bingo__logo {
    width: 169px;
    height: 57px;
  }

  .bingo__text {
    font-size: 60px;
    margin-bottom: 43px;
  }

  .bingo__subtext {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .bingo__photos {
    margin-bottom: 48px;
  }

  .bingo__user {
    width: 148px;
    height: 148px;
  }

  .bingo__user:first-child {
    margin-right: 58px;
  }

  .bingo__btn {
    height: 59px;
    font-size: 24px;
  }

  .message__btn {
    margin-bottom: 29px;
  }
}