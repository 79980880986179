
    /*.userprofile img{max-width:50%}*/
    .carusel-slide {
        align-items: center;
        background-color: #666;
        color: #999;
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        min-height: 10rem;
    }
    .carousel__indicators{top:0}
    @media (min-width:1280px) {
        .config__title {
            display: none
        }
    }
