
.select__gender {
  width: 100%;
}

.area__block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin-top: 24px;
}

.text__area {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text__area textarea {
  min-height: 120px !important;
  border-radius: 12px;
  background: #f1f0ef;
  border: none;
  padding: 12px;
}

.filepond--wrapper {
  width: 50%;
  margin: 0 auto;
  min-height: 50vh;
  background: #f1f0ef;
  border-radius: 10px;

}

.profile__settings .prof__card .card__gender img {
  width: 14px;
}

.filepond--root.filepond--hopper {
  height: 50vh !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.filepond--drop-label {
  min-height: 100% !important;
}

.setup__pop {
  display: flex;
  flex-direction: column;
  align-items: center;
}
