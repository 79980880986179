
    @import "../../assets/styles/index.scss";

    $cardsTotal: 3;
    $cardsPositionOffset: 20vh * 0.06;
    $cardsScaleOffset: 0.08;
    $defaultTranslation: $cardsPositionOffset * $cardsTotal;
    $defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);


    .card {

        @include absolute(0);

        @include flex-center();



        display: flex;

        opacity: 0;


        &.isCurrent {
            pointer-events: auto;
        }

        &.isAnimating {
            transition: transform 0.7s $ease-out-back;
        }
    }



    @for $i from 1 through $cardsTotal {
        $index: $i - 1;
        $translation: $cardsPositionOffset * $index;
        $scale: 1 - ($cardsScaleOffset * $index);

        .card:nth-child(#{$i}) {
            z-index: $cardsTotal - $index;
            opacity: 1;


        }
    }
